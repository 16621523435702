$theme1: #068d9d;
$theme2: #96d8e0;
$theme3: #d1eff1;
$theme4: #05707d;
$theme5: #eff3f6;
$theme6: #d1eff1;
$primary1: #554ba0;
$primary2: #5656cc;
$primary3: #a7abe0;
$primary4: #344591;
$primary5: #f6f4ff;
$black: #242328;
$dark: #615f67;
$light: #a7a6ac;
$super-light: #d8d8d8;
$danger: #ce555c;
$light-danger: #ffdfe0;
$success: #76b793;
$light-success: #e8f5eb;
$deep-warning: #ee75ee;
$warning: #ffa953;
$light-warning: #fff2e4;
$info: #e2acd7da;
$light-info: #EEDAEA;
$white: #ffffff;
$white2: #f7f7f7;
$white3: #f4f4f4;
$light-pink: #f1f1f1;